import React from "react"
import { Link } from "gatsby"
import { Container, Grid, Heading, Styled } from "theme-ui"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default ( props ) => {

  return (
    <Container
      as={`div`}
      sx={{
        my: [2, 4],
        pl: [3, 3, 3, 4, 4],
        pr: [3, 3, 3, 0, 0],
        h2: {
          fontSize: 0,
          fontWeight: `body`,
          "&:after": {
            backgroundColor: `black`,
            content: `""`,
            display: `block`,
            height: `1px`,
            width: 6,
            my: 2,
          },
        },
        a: {
          border: `1px solid black`,
          color: `black`,
          display: `block`,
          fontSize: 0,
          fontWeight: `bold`,
          px: 3,
          pt: [4, 4, 4, 5, 6],
          pb: 2,
          "&:hover": {
            backgroundColor: `gray.1`,
          },
        },
      }}
    >
      <Grid
        as={`div`}
        gap={1}
        columns={[`1fr`, `16rem 1fr`]}
      >
        <Heading as={`h2`}>Todos los proyectos</Heading>
        <Grid
          as={`div`}
          gap={1}
          columns={[2, 2, 2, 4, 4]}
        >
          <Styled.a as={Link} to={`/todos`}>Acceso a todos los proyectos. Todos los años. Todos los ámbitos.</Styled.a>
        </Grid>
      </Grid>
    </Container>
  )
}
