import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Main from "../components/main"
import MenuCats from "../components/menucats"
import MenuTags from "../components/menutags"
import MenuAll from "../components/menuall"
import Text from "../components/text"
import TextMin from "../components/textmin"
import { Heading, Styled } from "theme-ui"
import SEO from "../components/seo"

/*
 * Esta es la forma abreviada de declarar un componente
 * Fijate que usa {} por que tiene variables dentro
 * Luego hacer un return.
 * Mira comentarios en archivo layout
 *
 */
export default ({ data }) => {

  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      <Header page='frontpage'>
        <Heading as={`h1`}>{data.site.siteMetadata.title}</Heading>
      </Header>
      <Main>
        <Text
          textcolor='black'
          bgcolor='white'
        >
          <Heading as={`h2`}>Sobre</Heading>
          <Styled.p>El mapa de proyectos sociales tiene como finalidad:</Styled.p>
          <Styled.ul>
            <Styled.li>Contribuir a visualizar los proyectos que realizan las bibliotecas públicas para la mejora social de su entorno o comunidad.</Styled.li>
            <Styled.li>Mostrar al tercer sector las posibilidades de actuación conjuntas en el territorio.</Styled.li>
            <Styled.li>Evidenciar a las administraciones públicas la necesidad de seguir invirtiendo en las bibliotecas públicas.</Styled.li>
            <Styled.li>Potenciar el intercambio de conocimiento y aprender de las "mejores prácticas".</Styled.li>
          </Styled.ul>
          <Styled.p><strong>Nota</strong>: <em>Cada proyecto está en la lengua en la que se ha realizado: castellano o catalán</em>.</Styled.p>
        </Text>
        <MenuCats />
        <MenuTags />
        <MenuAll />
        <TextMin
          textcolor='black'
          bgcolor='gray.1'
        >
          <Styled.p>Cada año incorporamos los proyectos mejor evaluados en la convocatoria del Premio "Biblioteca Pública y compromiso social". En el mapa destacamos los aceptados, los finalistas y los ganadores. De los ganadores y finalistas hay un enlace al proyecto.</Styled.p>
          <Styled.p>Knowledge map of public library projects for combating social exclusion.<br/> <Styled.a as={Link} to={`/convocatoria-2014-es`}>First edition (2014)</Styled.a> was supported by a grant from the PL2020 Programme of the R&#x00026;W.</Styled.p>
        </TextMin>
      </Main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query frontPage {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
