import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Container, Grid, Heading, Styled } from "theme-ui"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default ( props ) => {

  const data = useStaticQuery(
    graphql`
      query MenuTags {
        allWordpressWpTagsMap {
          edges {
            node {
              name
              slug
              wordpress_id
            }
          }
        }
      }
    `
  )

  // todos los terms de la tax taxs_map en un array
  const allTermsTaxTagsMapAsArray = data.allWordpressWpTagsMap.edges

  return (
    <Container
      as={`div`}
      sx={{
        my: 4,
        pl: [3, 3, 3, 4, 4],
        pr: [3, 3, 3, 0, 0],
        h2: {
          fontSize: 0,
          fontWeight: `body`,
          "&:after": {
            backgroundColor: `black`,
            content: `""`,
            display: `block`,
            height: `1px`,
            width: 6,
            my: 2,
          },
        },
        a: {
          border: `1px solid black`,
          color: `black`,
          display: `block`,
          fontSize: 0,
          height: `100%`,
          px: 3,
          py: 4,
          pb: 2,
          "&:hover": {
            backgroundColor: `gray.1`,
          },
        },
        ".menu-item": {
          height: `100%`,
        },
        ".container-items": {
          alignItems: `flex-end`,
          gridAutoRows: `1fr`,
        },
      }}
    >
      <Grid
        as={`div`}
        gap={1}
        columns={[`1fr`, `16rem 1fr`]}
      >
        <Heading as={`h2`}>Convocatoria por ámbitos</Heading>
        <Grid
          as={`div`}
          className={`container-items`}
          gap={1}
          columns={[2, 2, 2, 4, 4]}
        >
          {
            allTermsTaxTagsMapAsArray.map(({ node, index }) => (
              <Box
                as={`div`}
                className="menu-item"
                key={node.wordpress_id}
              >
                <Styled.a as={Link} to={`/ambito/${node.slug}`}>{node.name}</Styled.a>
              </Box>
            ))
          }
        </Grid>
      </Grid>
    </Container>
  )
}
